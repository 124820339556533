<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            default="today"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전보건 내부심사 결과서 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'period',
            field: 'period',
            label: '실시기간',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'name',
            field: 'name',
            label: '수행계획서명',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'deptNames',
            field: 'deptNames',
            label: '피심사팀',
            align: 'center',
            style: 'width:110px',
            sortable: true,
          },
          {
            name: 'stepName',
            field: 'stepName',
            label: '진행상태',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.mam.review.list.url;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantName: '사업장1',
          period: '2022-01-01 ~ 2022-06-30',
          name: '2022년 생산팀 안전보건 내부심사 수행계획서',
          deptNames: '생산팀, 생산팀 A, 생산팀 B',
          stepName: '정리중',
        },
        {
          plantName: '사업장1',
          period: '2022-07-01 ~ 2022-10-31',
          name: '2022년 기술팀 안전보건 내부심사 수행계획서',
          deptNames: '기술팀 A, 기술팀 B',
          stepName: '승인',
        },
      ]
      // this.$http.url = this.listUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.grid.data = _result.data;
      // },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '내부심사 결과 상세';
      this.popupOptions.param = {
        id: row ? row.id : '',
      };
      this.popupOptions.target = () => import(`${'./internalResultDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
